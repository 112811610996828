import React from 'react';
import { Link } from 'gatsby';
import { colors, SpanPre, ColoredSpan, BoldSpan } from '../components/home/v2/StyledComponents';

// return 01 for 1, 12 for 12 and 34 for 134 (edge case)
// => do not use for number that can be greater than 99
export function toTwoDigits(number) {
  if (Number.isNaN(number) || number < 0) return '00';
  if (number !== Math.floor(number)) return String(number);
  return (`0${number}`).slice(-2) || '00';
}

// returns 01 for 1, 12 for 12 and 134 for 134
// returns 32.5 for 32.5
export function toTwoDigitsOrMore(number) {
  if (Number.isNaN(number) || number < 0) return '00';
  if (number >= 100) return number;
  if (number !== Math.floor(number)) return String(number);
  return (`0${number}`).slice(-2) || '00';
}

// returns 1,00 for 1, 12,00 for 12 and 134,00 for 134
// returns 32,50 for 32.5, 28,04 for 28.045615161
export function toTwoDecimalDigits(number) {
  if (Number.isNaN(number) || number < 0) return '00,00';
  const numberIt = number.toFixed(2).replace('.', ',');
  if (number >= 10) return numberIt;
  return (`0${numberIt}`) || '00,00';
}

// returns
//    Hello,
//    world
// for Hello, \nworld
export function slashNtoBr(text, nbsp = false) {
  const splittedText = text.split('\n');
  if (nbsp) {
    return splittedText.map((line, index) =>
      <SpanPre key={`${line}${index}`}>{line}{splittedText.length - 1 !== index && <br />}</SpanPre>);
  }
  return splittedText.map((line, index) =>
    <span key={`${line}${index}`}>{line}{splittedText.length - 1 !== index && <br />}</span>);
}

// return « Hello, world » for Hello, world
export function toWrappedText(text) {
  return <span>{'« '}{text}{' »'}</span>;
}

// returns
//    Hello,
//    world
// for Hello, \nworld
// return if wrap ===true «Hello, world»
export function toFormattedText(text, wrap = false, nbsp = false) {
  if (!wrap) return slashNtoBr(text, nbsp);
  return toWrappedText(slashNtoBr(text, nbsp));
}

// returns
//    Hello world (white)
// for <white>Hello world<white>
// returns
//    Hello world (yellow)
// for <yellow>Hello world<yellow>
export function toFormattedColoredText(text, color = 'white') {
  const splittedText = text.split(`<${color}>`).filter(line => line !== '');
  if (text.indexOf(`<${color}>`) === 0) {
    return splittedText.map((line, index) =>
      <ColoredSpan key={`${line}${index}`} color={index % 2 === 0 ? color : ''}>
        {toFormattedText(line, false, true)}
      </ColoredSpan>);
  }
  return splittedText.map((line, index) =>
    <ColoredSpan key={`${line}${index}`} color={index % 2 !== 0 ? color : ''}>
      {toFormattedText(line, false, true)}
    </ColoredSpan>);
}

// returns by default
//    Hello world (font-weight === 500)
// for <bold>Hello world<bold>
export function toFormattedBoldText(text, fontWeight = 500) {
  const splittedText = text.split('<bold>').filter(line => line !== '');
  if (text.indexOf('<bold>') === 0) {
    return splittedText.map((line, index) =>
      <BoldSpan key={`${line}${index}`} fontWeight={index % 2 === 0 ? fontWeight : 400}>
        {toFormattedText(line)}
      </BoldSpan>);
  }
  return splittedText.map((line, index) =>
    <BoldSpan key={`${line}${index}`} fontWeight={index % 2 !== 0 ? fontWeight : 400}>
      {toFormattedText(line)}
    </BoldSpan>);
}

// cSpell:ignore mmande
// return
// Commande for 1. Commande
// Commande for 121.Commande
// mmande   for Co.mmande
// Commande for Commande
export function toUnNumberedText(text) {
  const start = text.indexOf('.') + 1;
  if (start === 0) return text;
  return text.slice(start).trimStart();
}

export function toFormattedLinkedText(text, links) {
  const linksCopy = links && links.slice();
  return text.split('<link>').map((subtext, index) => (
    <span key={subtext}>
      {(index % 2 === 1) ?
        <Link to={linksCopy.shift()} style={{ color: colors.navy }}>
          {toFormattedText(subtext)}
        </Link> :
        toFormattedText(subtext)
      }
    </span>
  ));
}
